<nz-breadcrumb>
  <nz-breadcrumb-item>
    <a [routerLink]="['']">Track and Trace</a>
  </nz-breadcrumb-item>
  <ng-container *ngIf="breadcrumbs$ | async as breadcrumbs">
    <nz-breadcrumb-item *ngFor="let item of breadcrumbs let index = index" [ngSwitch]="index">
      <span *ngSwitchCase="breadcrumbs.length - 1">{{item.label | translate}}</span>
      <ng-container *ngSwitchCase="breadcrumbs.length - 2">
        <span *ngIf="hasLastBreadcrumb">{{item.label | translate}}</span>
        <a *ngIf="!hasLastBreadcrumb" [routerLink]="item.url">{{item.label | translate}}</a>
      </ng-container>
      <a *ngSwitchDefault [routerLink]="item.url">{{item.label}}</a>
    </nz-breadcrumb-item>
  </ng-container>
</nz-breadcrumb>

