import { CommonModule }          from '@angular/common';
import { NgModule }              from '@angular/core';
import { RouterModule }          from '@angular/router';
import { ExecuteWithPipeModule } from '@lib-pipes/execute-with';
import { RequestWrapperModule }  from '@widgets/request-wrapper';
import { NzLayoutModule }        from 'ng-zorro-antd/layout';
import { BreadcrumbsModule }     from './breadcrumbs';
import { LayoutComponent }       from './layout.component';
import { LoginToolModule }       from './login-tool';
import { NavsModule }            from './navs';

const ANT_DESIGN_MODULES = [
  NzLayoutModule
];

@NgModule({
  imports: [
    BreadcrumbsModule,
    CommonModule,
    ExecuteWithPipeModule,
    NavsModule,
    RouterModule,
    RequestWrapperModule,
    LoginToolModule,
    ANT_DESIGN_MODULES
  ],
  declarations: [LayoutComponent],
})
export class LayoutModule {
}
