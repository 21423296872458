import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router }                            from '@angular/router';
import { Observable, ReplaySubject }                                        from 'rxjs';
import { filter, map, startWith, takeUntil, tap }                           from 'rxjs/operators';
import isEmpty                                                              from 'lodash-es/isEmpty';
import includes                                                             from 'lodash-es/includes';
import { BreadcrumbsService }                                               from './breadcrumbs.service';

interface Breadcrumbs {
  label: string;
  url?: string;
}

function getDynamicBreadcrumbs(route: ActivatedRoute, url: string, breadcrumbs: Breadcrumbs[]): Breadcrumbs[] {
  const children: ActivatedRoute[] = route.children;
  if (!children.length) { return breadcrumbs; }
  for (const child of children) {
    const routeUrl = child.snapshot.url.map(x => x.path).join('/');
    if (!isEmpty(routeUrl)) { url += `/${routeUrl}`; }
    const label = child.snapshot.data.breadcrumb;
    if (!isEmpty(label) && !includes(breadcrumbs.map(x => x.label), label)) { breadcrumbs = [...breadcrumbs, {label, url}]; }
    return getDynamicBreadcrumbs(child, url, breadcrumbs);
  }
}

@Component({
  selector: 'app-breadcrumbs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './breadcrumbs.component.html'
})
export class BreadcrumbsComponent implements OnDestroy {

  destroy$ = new ReplaySubject(1);
  breadcrumbs$: Observable<Breadcrumbs[]> = this.getBreadcrumbs$();
  hasLastBreadcrumb: boolean;

  constructor(
    private cdr: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private breadcrumbsService: BreadcrumbsService
  ) {
    this.breadcrumbsService.lastBreadcrumb$.pipe(
      tap(value => this.breadcrumbs$ = this.getBreadcrumbs$(value)),
      tap(value => this.hasLastBreadcrumb = !!value),
      tap(() => this.cdr.markForCheck()),
      takeUntil(this.destroy$)
    ).subscribe();
  }

  getBreadcrumbs$(lastBreadcrumb?: string) {
    return this.router.events.pipe(
      startWith(new NavigationEnd(0, window.location.pathname, '')),
      filter(event => event instanceof NavigationEnd),
      map(() => this.getBreadcrumbs(this.route.root, '', [], lastBreadcrumb))
    );
  }

  getBreadcrumbs(route: ActivatedRoute, url: string, breadcrumbs, lastBreadCrumb?: string): Breadcrumbs[] {
    return lastBreadCrumb ?
      [...getDynamicBreadcrumbs(route, '', []), {label: lastBreadCrumb}] :
      getDynamicBreadcrumbs(route, '', []);
  }

  ngOnDestroy() {
    this.destroy$.next(1);
    this.destroy$.complete();
  }
}
