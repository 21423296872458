import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Nav }                                       from './navs.service';

@Component({
  selector: 'app-navs',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './navs.component.html'
})
export class NavsComponent {

  @Input() isCollapsed: boolean;
  @Input() navs: Nav[];

}
