import { NgModule }                                from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard }                               from '@services/auth';

const routes: Routes = [
  {
    path: 'welcome',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/welcome').then(m => m.WelcomeModule)
  },
  {
    path: 'reset-password',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/reset-password').then(m => m.ResetPasswordModule)
  },
  {
    path: '',
    canActivate: [AuthGuard],
    loadChildren: () => import('./views/track-n-trace').then(m => m.TrackNTraceModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules})],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
