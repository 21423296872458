import { Injectable }  from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private readonly durationInSeconds = 3;
  private readonly secToMinMultiplier = 1000;
  private readonly requestErrorMessage = 'The request could not be completed. Internal server error';
  private readonly fileLoadingErrorMessage = 'File upload error';
  private readonly docSentSuccessMessage = 'The document was successfully sent for processing';
  private readonly temporaryUnavailable = 'The function is temporarily unavailable';

  constructor(private snackBar: NzMessageService) {
  }

  public error(message: string) {
    this.snackBar.error(message, {
      nzDuration: this.durationInSeconds * this.secToMinMultiplier
    });
  }

  public requestError() {
    this.error(this.requestErrorMessage);
  }

  public loginError(message: string) {
    this.error(message);
  }

  public badRequestError(message: string) {
    this.error(message);
  }

  public success(message: string) {
    this.snackBar.success(message, {
      nzDuration: this.durationInSeconds * this.secToMinMultiplier
    });
  }

  fileLoadingError() {
    this.error(this.fileLoadingErrorMessage);
  }

  docSent() {
    this.success(this.docSentSuccessMessage);
  }

  info(message: string) {
    this.snackBar.info(message, {
      nzDuration: this.durationInSeconds * this.secToMinMultiplier,
    });
  }

  temporaryUnavailableError() {
    this.error(this.temporaryUnavailable);
  }
}
