import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Router }                                                          from '@angular/router';
import { environment }                                                     from '@environments/environment';
import { TargetMarket }                                                    from '@environments/environment.interfaces';
import { Locale }                                                          from '@lib-widgets/reactive/locale';
import { TranslateService }                                                from '@ngx-translate/core';
import { AccountService }                                                  from '@services/account';
import { CurrentUserService }                                              from '@services/current-user';
import { LocaleService }                                                   from '@services/locale';
import { NzSelectOptionInterface }                                         from 'ng-zorro-antd/select';
import { ProductGroup }                                                    from '../../models/product-group-utils';
import { UserRole }                                                        from '../../models/user/user-role-util';

@Component({
  selector: 'app-login-tool',
  templateUrl: './login-tool.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoginToolComponent {

  @Input() hasLogout: boolean;
  @Output() logOutChanged = new EventEmitter();

  productGroup = ProductGroup;
  userRole = UserRole;

  environment = environment;

  availableLanguages = this.localeService.availableLanguages;
  locale$ = this.localeService.localeState.value$;
  targetMarket$ = this.accountService.targetMarketState.value$;
  curUser$ = this.currentUserService.curUser.value$;

  marketOptions: NzSelectOptionInterface[] = this.accountService.markets
    .map(value => ({
      label: this.translateService.instant(`TARGET_MARKETS.${value.toUpperCase()}`),
      value
    }) as NzSelectOptionInterface)
    .sort((oA, oB) => oA.label?.toString().localeCompare(oB.label?.toString()));

  constructor(
    public accountService: AccountService,
    private currentUserService: CurrentUserService,
    public localeService: LocaleService,
    public router: Router,
    private translateService: TranslateService
  ) {
  }

  openSwaggerPage() {
    window.open(environment.coreApiUrl + 'swagger-ui.html');
  }

  onProductGroupChange(productGroup) {
    this.accountService.productGroupObservable.next(productGroup);
    this.router.navigate(['']);
  }

  onTargetMarketChange(targetMarket: TargetMarket) {
    this.accountService.targetMarketState.value = targetMarket;
    const layoutChildPath = this.router.url?.split('/').filter(Boolean)[0];
    this.router.navigate(['/', layoutChildPath].filter(Boolean));
  }

  onLocaleChange = (locale: Locale) => this.localeService.globalLocale = locale;
}
