import { HttpClient }  from '@angular/common/http';
import { Injectable }  from '@angular/core';
import { environment } from '@environments/environment';
import { State }       from '@lib-classes/state';
import { tap }         from 'rxjs/operators';
import { UserDto }     from '../../models/user/user-dto';

@Injectable({providedIn: 'root'})
export class CurrentUserService {

  curUser = new State<UserDto>({} as UserDto);

  constructor(
    private http: HttpClient
  ) {
  }

  getCurrentUser$ = () => {
    const url = environment.coreApiUrl + 'users/current';
    return this.http.get<UserDto>(url).pipe(
      tap(curUser => this.curUser.value = curUser),
      tap({error: () => this.curUser.reset()})
    );
  };

}
