/**
 * sessionIdleTimeoutMinutes - fill it if you need to log out a user after N minutes. "0" or absence this property or "isSsoAuth = true" disables this option
 */
import { Locale } from '@lib-widgets/reactive/locale';

export enum TargetMarket {
  KZ = 'KZ',
  UZ = 'UZ',
  KG = 'KG'
}

export interface Environment {
  production: boolean;
  coreApiUrl: string;
  isSsoAuth?: boolean;
  sessionIdleTimeoutMinutes?: number;
  defaultLanguage?: Locale;
  defaultMarket?: TargetMarket;
  markets?: TargetMarket[];
}
