import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TranslateService }                   from '@ngx-translate/core';
import { LocaleService }                      from '@services/locale';

@Component({
  selector: 'app-root',
  changeDetection: ChangeDetectionStrategy.OnPush,
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {

  constructor(
    private localeService: LocaleService,
    private translateService: TranslateService
  ) {
    this.translateService.setDefaultLang(this.localeService.localeState.value); // doesn't work for lazy children if do it by app.module providers
  }

}

