import { HttpClient }     from '@angular/common/http';
import { Injectable }     from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError }     from 'rxjs/operators';

export interface Nav {
  label: string;
  link: string;
  icon: string;
  target?: string;
  isExternal?: boolean;
}

@Injectable({providedIn: 'root'})
export class NavsService {

  constructor(
    private http: HttpClient
  ) {
  }

  getAdditionalLinks = (): Observable<Nav[]> => this.http.get<Nav[]>('/assets/additional-nav-links.json').pipe(
    catchError(() => of([]))
  );
}
