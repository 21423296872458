import { CurrencyPipe, registerLocaleData } from '@angular/common';
import { HttpClient }                       from '@angular/common/http';
import ru                                   from '@angular/common/locales/ru';
import en                                   from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule }        from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule }                    from '@angular/platform-browser';
import { BrowserAnimationsModule }          from '@angular/platform-browser/animations';
import { RouterModule }                     from '@angular/router';
import { environment }                      from '@environments/environment';
import { AppInterceptorsModule }            from '@interceptors';
import { Locale }                           from '@lib-widgets/reactive/locale';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader }              from '@ngx-translate/http-loader';
import { LocaleService }                    from '@services/locale';
import { en_US, NZ_I18N }                   from 'ng-zorro-antd/i18n';
import { NzMessageModule }                  from 'ng-zorro-antd/message';
import { NzNotificationModule }             from 'ng-zorro-antd/notification';
import { AppComponent }                     from './app.component';
import { AppRoutingModule }                 from './app-routing.module';
import { LayoutModule }                     from './layout';
import { CurrencyInputMaskDirective }       from './util/currency-input-mask/currency-input-mask.directive';

registerLocaleData(en);
registerLocaleData(ru);

const ANT_DESIGN_MODULES = [
  NzNotificationModule,
  NzMessageModule,
];

@NgModule({
  declarations: [
    AppComponent,
    CurrencyInputMaskDirective
  ],
  imports: [
    AppInterceptorsModule,
    LayoutModule,
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forRoot({
      loader: {
          provide: TranslateLoader,
          useFactory: (http: HttpClient) => new TranslateHttpLoader(http),
          deps: [HttpClient]
      }
    }),
    ANT_DESIGN_MODULES
  ],
  providers: [
    CurrencyPipe,
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: APP_INITIALIZER,
      multi: true,
      deps: [LocaleService],
      useFactory: (localeService: LocaleService) => () => (localeService.globalLocale = environment.defaultLanguage || Locale.en)
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
