import { TargetMarket }         from '@environments/environment.interfaces';
import { interval, Observable } from 'rxjs';
import { concatMap, startWith } from 'rxjs/operators';

export const join = (items: unknown[], separator: string) => items?.join(separator);

export function enumToValueArray<T extends 'string' | 'number'>(e, valueType: T) {
  return Object.keys(e).filter(k => typeof e[k] === valueType).map(k => e[k]) as Array<T extends 'string' ? string : number>;
}

export const feToBeFilter = <T = any>(
  feFilter,
  dateFromKey: keyof T | 'createdAtFrom' = 'createdAtFrom',
  dateToKey:  keyof T | 'createdAtTo' = 'createdAtTo'
): T => {
  if (!feFilter) return {} as T;
  const {dateRange, ...filter} = feFilter;
  const [fromDate, toDate] = dateRange || [];
  if (fromDate) fromDate.setHours(0);
  if (toDate) toDate.setHours(23, 59, 59, 999);
  return {
    ...filter,
    ...fromDate && {[dateFromKey]: fromDate.toJSON()},
    ...toDate && {[dateToKey]: toDate.toJSON()}
  };
};

export function byInterval<T>(rq$: Observable<T>, period = 10000) {
  return interval(period).pipe(
    startWith(rq$),
    concatMap(() => rq$)
  );
}

export const getTabInfoFn = <T extends keyof any>(
  tabs: number[],
  tabTargetMarketMap: Record<T, TargetMarket[]>,
  tabUrlMap: Record<T, string>,
  tabLabelMap: Record<T, string>
) => (tm: TargetMarket) => tabs?.filter(el => tabTargetMarketMap[el]?.includes(tm))
  .map(type => ({url: tabUrlMap[type], label: tabLabelMap[type]}))
  .filter(tabInfo => tabInfo.label) || [];
