import { NgModule }                from '@angular/core';
import { CommonModule }            from '@angular/common';
import { TranslateModule }         from '@ngx-translate/core';
import { ExecuteWithPipeModule }   from '@lib-pipes/execute-with';
import { NzButtonModule }          from 'ng-zorro-antd/button';
import { NzResultModule }          from 'ng-zorro-antd/result';
import { NzSpinModule }            from 'ng-zorro-antd/spin';
import { RequestWrapperComponent } from './request-wrapper.component';
import { LocalSpinnerModule }      from '../local-spinner';

const ANT_DESIGN_MODULES = [
  NzButtonModule,
  NzResultModule,
  NzSpinModule
];

@NgModule({
  imports: [
    CommonModule,
    LocalSpinnerModule,
    ExecuteWithPipeModule,
    TranslateModule.forChild(),
    ANT_DESIGN_MODULES
  ],
  declarations: [RequestWrapperComponent],
  exports: [RequestWrapperComponent]
})
export class RequestWrapperModule {
}
