<div style="margin-top: 50px" class="animated-long fadeIn">
  <div class="spinner-centered">
    <div class="lds-roller">
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</div>
