import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable }                                from '@angular/core';
import { environment }                               from '@environments/environment';
import { InactivitySessionService }                  from '@services/inactivity-session.service';

@Injectable()
export class IdleInterceptor implements HttpInterceptor {

    constructor(private inactivitySessionService: InactivitySessionService) {
    }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (environment.sessionIdleTimeoutMinutes && !environment.isSsoAuth) this.inactivitySessionService.resetTimer();
    return next.handle(request);
  }
}
