<ng-container *ngIf="(getUpdatedRequest | executeWith : request$) | async"></ng-container>

<div *ngIf="error else contentTmp" class="animated fadeIn">
  <nz-result nzStatus="error"
             [nzTitle]="'' + (error.status || 'Похоже, что-то пошло не так')"
             [nzSubTitle]="(isHTML | executeWith : error.message) ? htmlErrorTmp : error.message">
    <div nz-result-extra>
      <button *ngIf="!noRetry"
              nz-button class="shadow-sm"
              (click)="repeatRequest()"> {{ 'LABELS.RETRY' | translate }}
      </button>
      <button *ngIf="!noBack && (backFn || hasFirstRs)"
              nz-button nzType="primary" class="shadow"
              (click)="backFn ? backFn() : error = null; back.emit()"> {{ 'LABELS.BACK' | translate }}
      </button>
    </div>
  </nz-result>
  <ng-template #htmlErrorTmp>
    <span [innerHTML]="error.message"></span>
  </ng-template>
</div>


<ng-template #contentTmp>
  <nz-spin class="h-100"
           [nzSpinning]="isLoading"
           [nzIndicator]="loadingTmp"
           [style.min-height.px]="isLoading || !noMinHeight ? 120 : 0">
    <div *ngIf="hasFirstRs" class="h-100">
      <ng-content></ng-content>
    </div>
  </nz-spin>
</ng-template>
<ng-template #loadingTmp>
  <div style="height: 120px" class="animated-long fadeIn d-flex justify-content-center">
    <app-local-spinner style="height: 64px" class="my-auto"></app-local-spinner>
    <span *ngIf="loadingTip" class="text-muted text-center">{{loadingTip}}...</span>
  </div>
</ng-template>

