import { Injectable }                                             from '@angular/core';
import { HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AuthService }                                            from '@services/auth';
import isEmpty                                                    from 'lodash-es/isEmpty';


@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    let headers = isEmpty(request.headers.keys()) ? new HttpHeaders() : request.headers;
    headers = headers.set('X-Requested-With', 'XMLHttpRequest'); // сделано для работы saml login в рамках #99
    if (this.authService.token && !headers.has('Authorization')) headers = headers.set('Authorization', 'Bearer ' + this.authService.token);
    request = request.clone({headers});
    return next.handle(request);
  }
}
