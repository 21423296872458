import { CommonModule }       from '@angular/common';
import { NgModule }           from '@angular/core';
import { RouterModule }       from '@angular/router';
import { TranslateModule }    from '@ngx-translate/core';
import { NzIconModule }       from 'ng-zorro-antd/icon';
import { NzMenuModule }       from 'ng-zorro-antd/menu';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NavsComponent }      from './navs.component';

const ANT_DESIGN_MODULES = [
  NzIconModule,
  NzMenuModule,
  NzTypographyModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule.forChild(),
    ANT_DESIGN_MODULES
  ],
  exports: [NavsComponent],
  declarations: [NavsComponent]
})
export class NavsModule {
}
