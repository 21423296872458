import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  inject,
  Input,
  Output
}                                             from '@angular/core';
import { HttpErrorResponse }                  from '@angular/common/http';
import cloneDeep                              from 'lodash-es/cloneDeep';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap, delay }                                from 'rxjs/operators';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-request-wrapper',
  templateUrl: './request-wrapper.component.html',
  styles: [`
    :host { height: 100%; display: block }
    :host::ng-deep .ant-spin-container { height: 100% }
    :host::ng-deep .ant-spin-container:after { background-color: inherit }
  `]
})
export class RequestWrapperComponent<T = any> {

  private cdr = inject(ChangeDetectorRef);

  @Input() request$: Observable<T>;

  @Input() loadingTip: string;

  @Input() backFn: () => void;

  private _noBack = false;
  @Input() set noBack(prop: boolean) { this._noBack = typeof prop === 'boolean' ? prop : true; }
  get noBack(): boolean { return this._noBack; }

  private _noRetry = false;
  @Input() set noRetry(prop: boolean | string) { this._noRetry = typeof prop === 'boolean' ? prop : true; }
  get noRetry(): boolean { return this._noRetry; }

  // В значении true устанавливает min-height в '0', иначе - 120px
  private _noMinHeight = false;
  @Input() set noMinHeight(prop: boolean | string) { this._noMinHeight = typeof prop === 'boolean' ? prop : true; }
  get noMinHeight(): boolean { return this._noMinHeight; }

  @Output() firstResponse = new EventEmitter<T>();

  @Output() response = new EventEmitter<T>();

  @Output() back = new EventEmitter<T>();

  @Output() errorEvent = new EventEmitter<HttpErrorResponse>();

  public hasFirstRs: boolean;

  public data: T;

  private isLoading$ = new BehaviorSubject(false);

  public get isLoading(): boolean {
    return this.isLoading$.getValue();
  };

  public error: { message: string, status: number };

  repeatRequest = () => {
    this.request$ = cloneDeep(this.request$);
    this.cdr.markForCheck();
  }

  getUpdatedRequest = (request$: Observable<T>) => {
    this.error = null;
    if (!request$) return null;
    this.isLoading$.next(true);
    return request$.pipe(
      delay(0), // for correct processing of synchronously emitting observables (closes 8902)
      tap<T>({
        next: data => {
          this.data = data;
          this.response.emit(data);
          if (!this.hasFirstRs) {
            this.firstResponse.emit(this.data);
            this.hasFirstRs = true;
          }
          this.isLoading$.next(false);
        },
        error: (err: HttpErrorResponse) => {
          this.isLoading$.next(false);
          this.error = { message: err.message, status: err.status };
          this.errorEvent.emit(err);
          this.cdr.markForCheck();
        }
      })
    );
  }

  isHTML(str: string) {
    const a = document.createElement('div');
    a.innerHTML = str;
    for (let c = a.childNodes, i = c.length; i--;) {
      if (c[i].nodeType === 1) return true;
    }
    return false;
  }
}
