import { Injectable }                                   from '@angular/core';
import { State }                                        from '@lib-classes/state';
import { Locale }                                       from '@lib-widgets/reactive/locale';
import { TranslateService }                             from '@ngx-translate/core';
import { en_US, NzI18nInterface, NzI18nService, ru_RU } from 'ng-zorro-antd/i18n';

@Injectable({providedIn: 'root'})
export class LocaleService {

  localeState = new State<Locale>(null);

  availableLanguages: Record<Locale, NzI18nInterface> = {
    [Locale.ru]: ru_RU,
    [Locale.en]: en_US
  };

  public set globalLocale(locale: Locale) {
    this.translateService.use(locale);
    this.i18nService.setLocale(this.availableLanguages[locale]);
    this.localeState.value = locale;
  }

  constructor(
    public translateService: TranslateService,
    private i18nService: NzI18nService
  ) {
  }
}
