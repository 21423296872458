import { Injectable }                                           from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { AccountService }                                       from '@services/account';
import { Observable }                                           from 'rxjs';

@Injectable()
export class TargetMarketInterceptor implements HttpInterceptor {

  constructor(
    private accountService: AccountService
  ) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const targetMarket = this.accountService.targetMarketState.value;
    return next.handle(request.clone({
      params: !request.params.has('targetMarket') && targetMarket
        ? request.params.set('targetMarket', targetMarket)
        : request.params
    }));
  }
}
