import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { environment }                                                   from '@environments/environment';
import { Nav, NavsService }                                              from '@layout/navs/navs.service';
import { TranslateService }                                              from '@ngx-translate/core';
import { AuthService }                                                   from '@services/auth';
import { CurrentUserService }                                            from '@services/current-user';
import { LocaleService }                                                 from '@services/locale';
import { Observable, of, throwError }                                    from 'rxjs';
import { finalize, map, tap }                                            from 'rxjs/operators';

const COLLAPSED_SIDER_WIDTH_PX = 80;
const SIDER_WIDTH_PX = 250;

const NAVS: Nav[] = [
  {label: 'MASTER', link: 'master-data', icon: 'audit'},
  {label: 'OMS', link: 'oms', icon: 'branches'},
  {label: 'LABEL_CODES', link: 'label-codes', icon: 'qrcode'},
  {label: 'PRODUCTS', link: 'products', icon: 'shopping-cart'},
  {label: 'DOCS', link: 'docs', icon: 'file-done'},
];

@Component({
  selector: 'app-layout',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.scss']
})
export class LayoutComponent {
  readonly localeService = inject(LocaleService);
  readonly authService = inject(AuthService);
  readonly cdr = inject(ChangeDetectorRef);
  readonly navsService = inject(NavsService);
  readonly currentUserService = inject(CurrentUserService);
  readonly translateService = inject(TranslateService);

  readonly environment = environment;

  readonly SIDER_WIDTH_PX = SIDER_WIDTH_PX;
  readonly COLLAPSED_SIDER_WIDTH_PX = COLLAPSED_SIDER_WIDTH_PX;

  readonly locale$ = this.localeService.localeState.value$;
  readonly navs$ = this.navsService.getAdditionalLinks().pipe(
    map(rs => [...NAVS, ...rs?.map(el => ({...el, isExternal: true}))])
  );

  widthPx: number;
  curUser$ = this.getCurUser$();
  logOut$: Observable<any>;


  setWidthPx$ = (locale: Locale, navs: Nav[]) => {
    if (!locale || !navs) return;
    return this.translateService.get(navs.map(n => `NAVS.${n.label}`))
      .pipe(
        tap(rs => this.widthPx = Math.min(Math.max(...Object.values(rs).map((el: string) => el.length)) * 8 + 60, SIDER_WIDTH_PX)),
      );
  };

  getCurUser$() {
    const curUserState = this.currentUserService.curUser;
    if (curUserState.value.id) return curUserState.value$;
    return this.currentUserService.getCurrentUser$().pipe(
      tap(({
        error: () => environment.isSsoAuth
          ? throwError(() => 'Ошибка получения сведений о пользователе')
          : (this.logOut$ = this.getLogOut$())
      }))
    );
  }

  getLogOut$ = () => environment.isSsoAuth ? of(null) : this.authService.logout$().pipe(
    finalize(() => {
      this.logOut$ = null;
      this.cdr.markForCheck();
    })
  );
}
