<div class="d-flex justify-content-between align-middle">
  <nz-select style="width: 330px"
             [nzCustomTemplate]="customTargetMarketTmp"
             [ngModel]="targetMarket$ | async"
             [nzOptions]="marketOptions"
             (ngModelChange)="onTargetMarketChange($event)">
    <ng-template #customTargetMarketTmp let-selected>{{'LABELS.TARGET_MARKET' | translate}}: {{ selected.nzLabel }}</ng-template>
  </nz-select>
  <nz-select style="width: 330px"
             class="ml-3"
             [nzCustomTemplate]="customProductGroupTmp"
             [ngModel]="accountService.productGroupObservable | async"
             (ngModelChange)="onProductGroupChange($event)">
    <nz-option *ngFor="let group of productGroup | keyvalue"
               [nzValue]="group.value"
               [nzLabel]="('PRODUCT_GROUPS.' + (group.key | uppercase)) | translate">
    </nz-option>
    <ng-template #customProductGroupTmp let-selected>{{'LABELS.PRODUCT_GROUP' | translate}}: {{ selected.nzLabel }}</ng-template>
  </nz-select>
  <a nz-dropdown
     style="padding: 6px"
     class="ml-3"
     [nzDropdownMenu]="profileMenu">
    <i nz-icon nzType="user"></i>
    {{(((curUser$ | async)?.username || ('LABELS.BLANK_USERNAME' | translate)) | titlecase)}}
  </a>
  <nz-select class="ml-3"
             [ngModel]="locale$ | async"
             (ngModelChange)="onLocaleChange($event)">
    <nz-option *ngFor="let lang of availableLanguages | keyvalue"
               [nzValue]="lang.key"
               [nzLabel]="lang.key">
    </nz-option>
  </nz-select>
  <i nz-icon nzType="question-circle" routerLink="about" style="padding: 6px;" class="ml-3"></i>
</div>

<nz-dropdown-menu #profileMenu="nzDropdownMenu">
  <ul nz-menu>
    <li *ngIf="(curUser$ | async)?.role === userRole.ADMIN"
        nz-menu-item
        routerLink="/administration">{{'LABELS.ADMINISTRATION' | translate}}
    </li>
    <li nz-menu-item (click)="openSwaggerPage()">Swagger</li>
    <ng-container *ngIf="hasLogout">
      <li nz-menu-divider></li>
      <li nz-menu-item (click)="logOutChanged.emit()">{{'LABELS.LOGOUT' | translate}}</li>
    </ng-container>
  </ul>
</nz-dropdown-menu>
