import { HttpClient }         from '@angular/common/http';
import { Injectable }         from '@angular/core';
import { Router }             from '@angular/router';
import { environment }        from '@environments/environment';
import { CurrentUserService } from '@services/current-user';
import { of }                 from 'rxjs';
import { tap }                from 'rxjs/operators';

const AUTH_KEY = 'credentials';

export interface Auth {
  token: string;
  expirationAt: Date;
}

@Injectable({providedIn: 'root'})
export class AuthService {

  constructor(
    private currentUserService: CurrentUserService,
    private router: Router,
    private http: HttpClient
  ) {
  }

  private set auth(auth: Auth | null) {
    auth ? localStorage.setItem(AUTH_KEY, JSON.stringify(auth)) : localStorage.removeItem(AUTH_KEY);
  }
  private get auth(): Auth {
    const lsAuth = localStorage.getItem(AUTH_KEY);
    return lsAuth && JSON.parse(lsAuth);
  }

  get isLoggedIn() { return !!this.auth; }
  get token() { return this.auth?.token; }

  login$(body: {username: string; password: string}) {
    return this.http.post<Auth>(environment.coreApiUrl + 'login', body).pipe(
      tap(auth => this.auth = auth),
      tap({error: () => this.logout$()})
    );
  }

  logout$ = () => (this.isLoggedIn ? this.http.post(environment.coreApiUrl + 'logout', null) : of(null)).pipe(
    tap(() => this.auth = null),
    tap(() => this.currentUserService.curUser.reset()),
    tap(() => this.router.navigate(['welcome']))
  );
}
