<ul *ngIf="navs" nz-menu nzTheme="dark" nzMode="inline"
    [nzInlineCollapsed]="isCollapsed">
  <li *ngFor="let nav of navs"
      nz-menu-item
      nzMatchRouter>
    <ng-container *ngIf="'NAVS.' + nav.label | translate as label">
      <a *ngIf="!nav.isExternal" nz-typography nzEllipsis [attr.title]="label" [routerLink]="['/', nav.link]">
        <ng-container *ngTemplateOutlet="navTmp; context: {icon: nav.icon, label}"></ng-container>
      </a>
      <a *ngIf="nav.isExternal" nz-typography nzEllipsis [attr.title]="label" [href]="nav.link" [target]="nav.target">
        <ng-container *ngTemplateOutlet="navTmp; context: {icon: nav.icon, label}"></ng-container>
      </a>
    </ng-container>
    <ng-template #navTmp let-icon=icon let-label=label>
      <i nz-icon [nzType]="icon"></i>
      <span *ngIf="!isCollapsed">{{label}}</span>
    </ng-template>
  </li>
</ul>
