import { CommonModule }          from '@angular/common';
import { NgModule }              from '@angular/core';
import { FormsModule }           from '@angular/forms';
import { RouterModule }          from '@angular/router';
import { TranslateModule }       from '@ngx-translate/core';
import { NzDropDownModule }      from 'ng-zorro-antd/dropdown';
import { NzIconModule }          from 'ng-zorro-antd/icon';
import { NzSelectModule }        from 'ng-zorro-antd/select';
import { LoginToolComponent }    from './login-tool.component';

const ANT_DESIGN_MODULES = [
  NzIconModule,
  NzDropDownModule,
  NzSelectModule
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    TranslateModule.forChild(),
    ANT_DESIGN_MODULES
  ],
  declarations: [LoginToolComponent],
  exports: [LoginToolComponent]
})
export class LoginToolModule {
}
