import { BehaviorSubject } from 'rxjs';
import { delay }           from 'rxjs/operators';

export class State<T> {

  private _state: BehaviorSubject<T>;
  private readonly _initialValue: T;

  public set value(value: T) { this._state.next(value); }
  public get value() { return this._state.getValue(); }
  public get value$() { return this._state.asObservable().pipe(delay(0)); }

  constructor(initialValue: T) {
    this._state = new BehaviorSubject(initialValue);
    this._initialValue = initialValue;
  }

  public reset() { this.value = this._initialValue; }

}
