import { Injectable }                                                              from '@angular/core';
import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { environment }            from '@environments/environment';
import { AlertService }           from '@services/alert-service';
import { AuthService }            from '@services/auth';
import { Observable, throwError } from 'rxjs';
import { catchError, filter }     from 'rxjs/operators';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthService,
    private alertService: AlertService
  ) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      filter(event => event.type !== 0),
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse) {
          if (error.error instanceof ErrorEvent) {
              console.error(error.error.message);
          } else if (error.status >= 400 && error.status < 500) {
              if (error.status === 401) {
                environment.isSsoAuth
                  ? window.location.assign(`${environment.coreApiUrl}sso/saml/login?redirectTo=${window.location.href}`)
                  : this.authService.logout$().subscribe();
                return throwError(error);
              } else if (error.status === 422) {
                this.alertService.badRequestError(error.error.message);
              } else {
                this.alertService.badRequestError(error.message);
              }
              console.error(
                  `Bad request ${error.status},` +
                  `error: ${error.message}`);
          } else {
              console.error(
                  `Server returned ${error.status},` +
                  `error: ${error.message}`);
          }
        } else {
            console.error(error);
        }
        return throwError(error);
      }));
  }
}
