import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable }              from '@angular/core';
import { environment }             from '@environments/environment';
import { TargetMarket }            from '@environments/environment.interfaces';
import { State }                   from '@lib-classes/state';
import { BehaviorSubject }         from 'rxjs';
import { enumToValueArray }        from '../../functions';
import { ProductGroup }            from '../../models/product-group-utils';

@Injectable({providedIn: 'root'})
export class AccountService {

  productGroup = ProductGroup;
  targetMarket = TargetMarket;

  public markets: TargetMarket[] = environment.markets || enumToValueArray(this.targetMarket, 'string') as TargetMarket[];
  targetMarketState = new State<TargetMarket>(this.markets.includes(environment.defaultMarket) ? environment.defaultMarket : this.markets[0]);

  productGroupObservable = new BehaviorSubject(this.productGroup.pharma); // todo get rid
  productGroupState = new State<ProductGroup>(this.productGroup.pharma); // todo spread this approach through app

  constructor(
    private http: HttpClient
  ) {
  }

  resetPassword(newPassword: string, jwt: string) {
    const url = `${environment.coreApiUrl}users/current/password`;
    return this.http.put(url, {
      password: newPassword
    }, {
      headers: new HttpHeaders().set('Authorization', `Bearer ${jwt}`)
    });
  }

}
