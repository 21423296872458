import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'executeWith'})
export class ExecuteWithPipe implements PipeTransform {
  transform<T, G>(fn: (...args: any) => G, ...args: any[]): G {
    return fn && fn(...args);
  }
}

@NgModule({
  declarations: [ExecuteWithPipe],
  exports: [ExecuteWithPipe]
})
export class ExecuteWithPipeModule {
}
