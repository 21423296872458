<ng-container *ngIf="logOut$ | async"></ng-container>

<app-request-wrapper #curUserRs [request$]="curUser$">
  <nz-layout *ngIf="curUserRs.data" class="animated fadeIn">
    <nz-header class="position-fixed header-layer-shadow d-flex w-100 px-4">
      <app-breadcrumbs class="align-self-center"></app-breadcrumbs>
      <app-login-tool style="right: 16px; top: 10px"
                      class="position-fixed"
                      [hasLogout]="!environment.isSsoAuth"
                      (logOutChanged)="logOut$ = getLogOut$()">
      </app-login-tool>
    </nz-header>
    <nz-layout>
      <app-request-wrapper #navsRs [request$]="navs$">
        <nz-sider #sider
                  nzCollapsible
                  class="py-3"
                  [nzWidth]="widthPx || SIDER_WIDTH_PX"
                  [nzCollapsedWidth]="COLLAPSED_SIDER_WIDTH_PX">
          <app-navs *ngIf="(setWidthPx$ | executeWith : (locale$ | async) : navsRs.data) | async"
                    [navs]="navsRs.data"
                    [isCollapsed]="sider.nzCollapsed">
          </app-navs>
        </nz-sider>
        <nz-content class="px-2 py-3" [style.margin-left.px]="sider.nzCollapsed ? COLLAPSED_SIDER_WIDTH_PX : widthPx || SIDER_WIDTH_PX">
          <div style="min-height: 234px" class="card border-0 p-3">
            <router-outlet></router-outlet>
          </div>
        </nz-content>
      </app-request-wrapper>
    </nz-layout>
  </nz-layout>
</app-request-wrapper>
