import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule }                            from '@angular/core';
import { ApiInterceptor }                      from './api';
import { AuthInterceptor }                     from './auth';
import { ErrorInterceptor }                    from './error';
import { IdleInterceptor }                     from './idle/idle.interceptor';
import { TargetMarketInterceptor }             from './target-market';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    {provide: HTTP_INTERCEPTORS, useClass: TargetMarketInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: ApiInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true},
    {provide: HTTP_INTERCEPTORS, useClass: IdleInterceptor, multi: true},
  ],
  exports: [HttpClientModule]
})
export class AppInterceptorsModule {
}
