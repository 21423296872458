import { Injectable }                                                        from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpHeaders } from '@angular/common/http';
import { environment }                                                       from '@environments/environment';
import isEmpty                                                               from 'lodash-es/isEmpty';
import includes                                                              from 'lodash-es/includes';
import { Observable }                                                        from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let headers = isEmpty(request.headers.keys()) ? new HttpHeaders() : request.headers;
    if (!headers.has('Accept')) { headers = headers.set('Accept', 'application/json'); }
    const apiUrl = !includes(request.url, 'http') && !includes(request.url, 'assets') && environment.coreApiUrl;
    // todo сделать простое добавление "sso/" в конец #98
    const url = `${apiUrl || ''}${request.url.replace(environment.isSsoAuth && '/api/core/', '/api/core/sso/')}`;
    request = request.clone({headers, withCredentials: true, url});
    return next.handle(request);
  }
}
