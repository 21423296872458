import { inject, Injectable }             from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { environment }                    from '@environments/environment';
import { AuthService }                    from './auth.service';

@Injectable({providedIn: 'root'})
export class AuthGuard {
  readonly router = inject(Router);
  readonly authService = inject(AuthService);

  canActivate(route: ActivatedRouteSnapshot): boolean | Promise<boolean> {
    const path = route.routeConfig.path;
    if ((this.authService.isLoggedIn || environment.isSsoAuth) && ['welcome', 'reset-password'].includes(path))
      this.router.navigate(['']);
    if (!this.authService.isLoggedIn && !environment.isSsoAuth && !['welcome', 'reset-password'].includes(path))
      this.router.navigate(['welcome']);
    return true;
  }
}
