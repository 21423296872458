import { Injectable, OnDestroy }  from '@angular/core';
import { environment }            from '@environments/environment';
import { AuthService }            from '@services/auth';
import { interval, Subscription } from 'rxjs';
import { tap }                    from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class InactivitySessionService implements OnDestroy {

    private readonly lsLastRequestTimeKey = 'lastApiRequest';
    private timerSubscription: Subscription;

    constructor(
      private authService: AuthService
    ) {
    }

    resetTimer() {
        const currentTime = Date.now();
        localStorage.setItem(this.lsLastRequestTimeKey, currentTime.toString());
        this.unsubscribe();
        this.timerSubscription = interval(1000).pipe(
            tap(_ => {
                const lastRequestTime = new Date(parseInt(localStorage.getItem(this.lsLastRequestTimeKey)));
                if ((Date.now() - lastRequestTime.getTime()) >= environment.sessionIdleTimeoutMinutes * 60 * 1000) {
                    this.authService.logout$().subscribe();
                    this.unsubscribe();
                }
            })
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.unsubscribe();
    }

    private unsubscribe() {
        this.timerSubscription?.unsubscribe();
    }
}
